<template>
  <div class="mainPage">
    <div class="mainPageContent">
      <div class="contentPanel pb-0">
        <div class="headerLogo">
          <img
            src="../../assets/logo_b.png"
            alt="Osmose"
            @click="openDashboard()"
          />
        </div>
        <h6 class="poleId">
          <strong> Pole ID:</strong> {{ damageData.poleData.poleId }}
        </h6>
        <div class="contactSection m-0">
          <div class="textCenter info1 fnt24">Select Damage to Add</div>
        </div>
      </div>
      <div class="damageBox">
        <div class="Box1">
          <div class="listBox" @click="openDamageDetails('ExposedPocket')">
            <img src="../../assets/arc1.png" alt="Exposed Pocket" />
            <div class="boxTitle">Exposed Pocket</div>
          </div>
        </div>
        <div class="Box1">
          <div class="listBox" @click="openDamageDetails('EnclosedPocket')">
            <img src="../../assets/arc2.png" alt="Enclosed Pocket" />
            <div class="boxTitle">Enclosed Pocket</div>
          </div>
        </div>
        <div class="Box1">
          <div class="listBox" @click="openDamageDetails('Mechanical')">
            <img src="../../assets/arc4.png" alt="Mechanical Damage" />
            <div class="boxTitle">Mechanical Damage</div>
          </div>
        </div>
        <div class="Box1">
          <div class="listBox" @click="openHollowPole">
            <img src="../../assets/arc3.png" alt="Hollow Pole" />
            <div class="boxTitle">Hollow Pole</div>
          </div>
        </div>
        <div class="textCenter">
          <a
            href="javascript: void(0);"
            class="backLink txtUppercase"
            @click="onCancel()"
          >
            CANCEL
          </a>
        </div>
      </div>

      <shared-footer></shared-footer>
      <div class="popUp" v-if="popup">
        <div class="popUpBox">
          <p class="popupText">
            Cannot add Enclosed Pockets while Hollow Pole exists.
          </p>
          <div class="modalFooter">
            &nbsp;&nbsp;
            <button class="btnRoundedFull" v-on:click="closePrompt()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedFooter from "../SharedFooter.vue";
import { mapGetters } from "vuex";
import { store } from "../../store/index.js";
import Eruda from 'eruda';

export default {
  name: "DamageDetail",

  data: () => ({
    damageData: {
      type: "",
      poleData: {},
    },
    popup: false,
  }),

  components: {
    "shared-footer": SharedFooter,
  },

  computed: {
      ...mapGetters(["getPoleRecord"]),
      
  },

  mounted() {
      //debugger;
      this.damageData.poleData = JSON.parse(this.getPoleRecord);
      if (localStorage.getItem("ErudaEnabled") === "Yes")
          Eruda.init();
  },

  methods: {
    openDashboard() {
      this.$router.replace("/dashboard");
    },

    closePrompt() {
      this.popup = false;
    },

      openDamageDetails(type) {
          //debugger;
      if (
          type == "EnclosedPocket" &&
          JSON.stringify(this.damageData.poleData.damages).toLowerCase().includes(
          '"type":"hollowpole"'
        )
      ) {
        this.popup = true;
      } else {
        this.damageData.type = type;
        store.dispatch("setDamageRecord", JSON.stringify(this.damageData));
        this.$router.push("/damageDetail");
      }
    },

    openHollowPole() {
      store.dispatch("setDamageRecord", JSON.stringify(this.damageData));
      this.$router.push("/hollowPole");
    },

    onCancel() {
      this.$router.replace("/evaluation");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.headerLogo img {
  width: 200px;
}
.boxCircle {
  background: #003468;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.poleId {
  font-size: 16px;
  font-weight: normal;
  margin: 10px 0px;
}
.damageBox {
  padding-bottom: 50px;
}
.listInlinecst {
  text-align: left;
  li a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    display: block;
    border-bottom: 1px solid #d5d5d5;
    padding: 15px 5px;
    margin-bottom: 10px;
  }
}
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.popUpBox {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #d2e4ff;
  padding: 10px 10px 25px 10px;
  width: 230px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  p {
    font-weight: 600;
  }
}
.popupText {
  padding: 20px 20px;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.contactSection {
  margin: 7% 0px;
}
.btnRounded {
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  padding: 6px;
  border-radius: 50px;
  color: #2c95ff;
  min-width: 90px;
  cursor: pointer;
}
.btnRoundedFull {
  background: #2c95ff;
  border: 2px solid #2c95ff;
  padding: 6px;
  border-radius: 50px;
  color: #fff;
  min-width: 90px;
  cursor: pointer;
}
.mainPage {
  height: calc(100vh - 50px);
}
.headerLogo {
  img {
    width: 230px;
  }
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
    .active {
      font-weight: 700;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}

.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
  font-size: 20px;
}
.btndef {
  background: #ccc;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.error {
  color: #f00;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0px;
}
.info2 {
  color: #2c95ff;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0px;
  text-decoration: none;
  display: inline-block;
}
.damageBox {
}
.Box1 {
  border-top: 1px solid #d5d5d5;
  padding: 15px 0px;
  cursor: pointer;
}
.Box1 img {
  width: 70px;
}
.listBox {
  width: 310px;
  margin: auto;
  color: #003468;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.boxTitle {
  margin-left: 20px;
}
.pb-0 {
  padding-bottom: 0px;
}
//Tabs Only

@media (min-width: 320px) {
  .maxHgt {
    height: calc(100vh - 55vh);
    overflow: auto;
  }
}
@media (min-width: 375px) {
  .maxHgt {
    height: calc(100vh - 50vh);
  }
}
@media (min-width: 1023.5px) {
  .maxHgt {
    height: calc(100vh - 55vh);
  }
}
@media (min-width: 768px) {
  .listBox {
    width: 450px;
  }
  .contentPanel {
    padding-top: 5rem;
    width: 450px;
  }

  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .title1 {
    margin: 3% 0px;
  }
}

//Small mobile
@media (max-width: 400px) {
  .headerLogo img {
    width: 125px;
  }
  .info1 {
    font-size: 14px;
  }
  .Box1 img {
    width: 50px;
  }
}
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>
